<template>
  <div>
    <br/><br/>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <br/>
          <p v-if="popup" @click="showModal('empiecement')">
            <template v-if="flux.level2 === 'DOUDOUNE'">
              L'empiècement permet de couvrir une cloison de votre doudoune avec un tissu identique ou approchant.
            </template>
            <template v-else>
              Après avoir sélectionné ensemble la matière première, nous réalisons un empiècement pour réparer votre
              vêtement
            </template>
            <v-icon color="black darken-2" dark small>
              mdi-information
            </v-icon>
          </p>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <template v-if="flux.level2 === 'DOUDOUNE'">
            <p>Précisions :</p>
            <ul class="ul_normal">
              <li>Nous avons un tissu similaire ou identique, nous l'utilisons pour réparer votre vêtement</li>
              <li>Concernant les tissus JOTT, nous possédons quelques tisssus des collections 2022 /2023 /2024 mais nous
                n'avons pas tous les tissus. Nous utiliserons le tissu le plus proche du coloris de votre doudoune.
              </li>
              <li>Nous n'avons pas le coloris adapté, nous prendrons contact avec vous pour vous proposer solution plus
                adaptée : extraction de matière, couture simple, symétrie par empiècement. (des frais supplémentaires
                peuvent vous être demandés)
              </li>
            </ul>
          </template>
          <template v-else>
            <p>Réalisation d’un empiècement au forfait qui comprend <br/><br/>
              <ul class="ul_normal">
                <li>l'échange avec vous par téléphone ou mail et photo pour sélectionner le tissu.</li>
                <li>la matière première.</li>
                <li>la main d'oeuvre.</li>
              </ul>
            </p>
          </template>
          <br/>
          <h6>EMPLACEMENT</h6>
          <v-text-field
            v-model="emplacement"
            dense
            outlined
            placeholder="Décrivez votre emplacement"
          ></v-text-field>
          <br/>
          <image-preview
            :is-bonus-eligible="bonus > 0"
            :value="pictures"
            @change="v => pictures = v"
          />
        </v-col>
        <v-col v-if="opt" cols="12">
          <div class="spacer"></div>
          <table>
            <tr>
              <td colspan="2" style="padding: 0px 0px 0px 5px;">
                <v-checkbox
                  v-model="precision"
                  :label="label"
                  @click="getPrice()"
                >
                  <template v-slot:label>
                    <div>{{ label }}</div>
                  </template>
                </v-checkbox>
              </td>
            </tr>
          </table>
        </v-col>
        <price :amount="price" :bonus="bonus" display-bottom-line/>
      </v-row>
      <bonus-image-confirm
        v-if="bonus > 0 && pictures.length < 2"
        @skip-bonus="bonus = 0"
      />
      <confirm-flow
        v-else-if="!this.flux.indexEditProduit"
        @finalize="noarticle"
        @new-repair="onSelectNewRepair"
        @new-article="noarticle(false)"
      />
      <v-row v-else>
        <v-col v-if="error" cols="12">
          <div class="text-center" style="color:#FF0000">Vous devez sélectionner le nombre de trous par
            dimensions
          </div>
        </v-col>
        <v-col class="text-center" cols="12">
          <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
        </v-col>
      </v-row>
    </v-form>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import empPrice from '../../assets/data/price/empiecement.json';
import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      opt: false,
      price: 0,
      bonus: 0,
      emplacement: null,
      reduction: 0,
      label: null,
      popup: true,
      pictures: [],
      error: false,
      precision: false,
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  created() {
    if (this.flux.activeTab !== 'empiecement') {
      return null;
    }
    this.price = empPrice[0][this.flux.level2].prix
    this.bonus = empPrice[0][this.flux.level2]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0

    if (empPrice[0][this.flux.level2].label !== undefined) {
      this.opt = true
      this.label = empPrice[0][this.flux.level2].label
      this.reduction = empPrice[0][this.flux.level2].reduction
    }
    if (this.flux.isDouble) {
      this.price = this.price * 1.2
    }

    this.price = Math.round(this.price * 100) / 100

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.pictures = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['pictures']
      this.precision = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['precision']
    }
  },
  updated() {
    if (this.flux.activeTab !== 'empiecement') {
      return null;
    }

    if (empPrice[0][this.flux.level2].label !== undefined) {
      this.opt = true
      this.label = empPrice[0][this.flux.level2].label
      this.reduction = empPrice[0][this.flux.level2].reduction
    }

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.pictures = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['pictures']
      this.precision = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['precision']
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setActiveTab', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.setConstat('cart')
          this.$emit('showmodal', 'cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          type: 'Empiècement',
          componentName: this.flux.level3,
          activeTab: 'empiecement',
          emplacement: this.emplacement,
          precision: this.precision,
          precisions: this.precision ? this.label : null,
          qte: 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          pictures: this.pictures
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        let reparation = {}

        reparation = {
          id: this.flux.cptReparation,
          type: 'Empiècement',
          componentName: this.flux.level3,
          activeTab: 'empiecement',
          emplacement: this.emplacement,
          precision: this.precision,
          precisions: this.precision ? this.label : null,
          qte: 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : 0,
          pictures: this.pictures
        }

        liste_reparations.push(reparation)

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.setLevel3(null)
        this.setComponentName(null)
        this.getTotalCart(this.products)
      }
    },
    getPrice() {
      this.price = empPrice[0][this.flux.level2].prix
      if (this.flux.isDouble) {
        this.price = this.price * 1.2
      }
      if (this.precision === true) {
        this.price = this.price + this.reduction
      }
      this.price = Math.round(this.price * 100) / 100
      this.error = false
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    }
  },
  components: {
    ConfirmFlow,
    ImagePreview,
    BonusImageConfirm,
    Price
  }
}
</script>
